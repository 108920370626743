<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="8" lg="6">
        <v-card>
          <v-card-title>
            <h2>Generate Token</h2>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-text-field
                label="Meeting Room Code"
                :prefix="meetingBaseUrl"
                v-model="room"
                :disabled="isAllRoom"
                outlined
                dense
                :rules="[rules.required, roomRequiredRule]"
              >
                <template #append-outer>
                  <v-checkbox
                    v-model="isAllRoom"
                    label="All Room"
                    class="ma-0 pt-0"
                  ></v-checkbox>
                </template>
              </v-text-field>
              <v-text-field
                label="Name"
                v-model="name"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                label="Email"
                v-model="email"
                :rules="[rules.required, rules.email]"
                outlined
                dense
              ></v-text-field>
              <v-select
                label="Affiliation"
                outlined
                dense
                v-model="affiliation"
                :items="affiliationOptions"
              ></v-select>
              <!-- <v-btn color="primary" @click="generateToken"
                >Generate Token</v-btn
              > -->
              <v-btn
                color="primary"
                @click="generateToken"
                :disabled="!isGenerateTokenButtonEnabled"
              >
                Generate Token
              </v-btn>

              &nbsp;
              <v-btn
                color="primary"
                v-if="name && email && generatedToken"
                @click="sendToken"
              >
                Send Email
              </v-btn>
            </v-form>
          </v-card-text>
          <v-row class="pa-4">
            <v-col cols="12">
              <v-card-text
                >Token
                <v-btn icon x-small @click="$copyToClipboard(generatedToken)">
                  <v-icon> mdi-content-copy </v-icon>
                </v-btn></v-card-text
              >
              <v-textarea readonly solo rows="2" v-model="generatedToken">
              </v-textarea>
            </v-col>
            <v-col cols="12">
              <v-card-text
                >Meeting Link
                <v-btn
                  icon
                  x-small
                  @click="$copyToClipboard(generatedMeetingLink)"
                >
                  <v-icon> mdi-content-copy </v-icon>
                </v-btn>
              </v-card-text>
              <v-textarea readonly v-model="generatedMeetingLink" solo rows="3">
              </v-textarea>

              <v-row class="pa-4" style="border: 2px solid rgb(58, 17, 207)">
                <h2>Share Meeing Link Externally</h2>
                <v-row>
                  <v-col>
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :rules="$requiredRules"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="start"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="start_date"
                          label="Date *"
                          prepend-inner-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :rules="$requiredRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-if="menu1"
                        ampm-in-title
                        format="ampm"
                        color="red lighten-1"
                        v-model="start_date"
                        :max="end"
                        @click:minute="$refs.menu1.save(start_date)"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col>
                    <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :rules="$requiredRules"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="end"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="meeting_time"
                          label="Start Time *"
                          prepend-inner-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :rules="$requiredRules"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="menu2"
                        ampm-in-title
                        format="ampm"
                        color="red lighten-1"
                        v-model="meeting_time"
                        :max="end"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col style="margin-top: 10px">
                    <v-select
                      label="Duration"
                      outlined
                      dense
                      v-model="duration"
                      :items="durationOptions"
                      :rules="$requiredRules"
                    ></v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-textarea
                    v-model="emailList"
                    label="Enter Email Addresses (separated by commas)"
                    rows="5"
                    outlined
                    :rules="$requiredRules"
                  ></v-textarea>

                  <!-- <v-btn @click="sendEmails" color="primary" class="ma-auto"
                    >Send</v-btn
                  > -->
                  <v-btn
                    @click="sendEmails"
                    color="primary"
                    class="ma-auto"
                    :disabled="!isSendButtonVisible"
                  >
                    Send
                  </v-btn>
                </v-row>
              </v-row>
              <v-alert
                v-model="showAlert"
                dismissible
                border="top"
                color="success"
                icon="mdi-check-circle"
              >
                Email sent successfully!
              </v-alert>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      rules: {
        required: (value) => !!value || "This field is required",
        email: (value) =>
          /.+@.+\..+/.test(value) || "Enter a valid email address",
      },
      menu1: false,
      menu2: false,
      menu3: false,
      showAlert: false,

      start: "",
      end: "",
      isAllRoom: false,
      room: "",
      name: "",
      email: "",
      affiliation: "member",
      duration: "",
      affiliationOptions: ["owner", "member"],
      durationOptions: [
        "15 Minutes",
        "30 Minutes",
        "45 Minutes",
        "60 Minutes",
        "90 Minutes",
        "120 Minutes",
        "150 Minutes",
        "180 Minutes",
      ],
      generatedToken: "",
      generatedMeetingLink: "",
      emailList: "",
      start_date: null,
      meeting_time: null,
      startMenu: false,
      endMenu: false,
      roomRequiredRule: (value) => !!value || "Meeting Room Code is required",
    };
  },
  computed: {
    isGenerateTokenButtonEnabled() {
      return this.room;
    },

    isEmailListValid() {
      const emailAddresses = this.emailList
        .split(",")
        .map((email) => email.trim());
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      const isValid = emailAddresses.every((email) => emailRegex.test(email));

      if (!isValid) {
        this.showInvalidEmailAlert();
      }

      return isValid;
    },
    meetingBaseUrl() {
      // return process.env.VUE_APP_JITSI_BASEURL
      //   ? process.env.VUE_APP_JITSI_BASEURL.replace("https://") + "/"
      //   : "s101.meetingme.live/";
      return "https://org.meetingme.live/meeting/".replace("https://", "");
    },
    isSendButtonVisible() {
      return (
        this.start_date &&
        this.meeting_time &&
        this.duration &&
        this.emailList.trim() !== "" &&
        this.generatedToken
      );
    },
  },
  methods: {
    showInvalidEmailAlert() {
      alert("Invalid email address detected. Please check the email list.");
    },

    sendEmails() {
      if (!this.isEmailListValid) {
        console.error("Invalid email addresses in the list");
        return;
      }
      const emailAddresses = this.emailList
        .split(",")
        .map((email) => email.trim());
      const generated_token = this.generatedMeetingLink;
      const start_date = moment(this.start_date).format("Do MMM YY");
      const meeting_time = this.meeting_time;
      const duration = this.duration;

      const data = {
        emailAddresses,
        generated_token,
        start_date,
        meeting_time,
        duration,
      };
      this.$api
        .post("meetings/meetings_session/send_mail_token/", data)
        .then(() => {
          // Show the alert for 3 seconds
          this.showAlert = true;
          setTimeout(() => {
            this.showAlert = false;
          }, 3000);
        });
    },
    sendToken() {
      const generated_token = this.generatedMeetingLink;
      const email = this.email;
      const name = this.name;

      const data = {
        generated_token,

        email,
        name,
      };
      this.$api
        .post("meetings/meetings_session/send_mail_token_personal/", data)
        .then(() => {
          // Show the alert for 3 seconds
          this.showAlert = true;
          setTimeout(() => {
            this.showAlert = false;
          }, 3000);
        });
    },
    generateToken() {
      this.$axios
        .post("/meetings/access-token/", {
          room: this.isAllRoom ? "*" : this.room,
          name: this.name,
          email: this.email,
          affiliation: this.affiliation,
        })
        .then((res) => {
          this.generatedToken = res.data.token;
          this.generatedMeetingLink = res.data.meeting_link.replace(
            "https://s101.meetingme.live/",
            "https://org.meetingme.live/meeting/"
          );
        });
    },
  },
};
</script>

<style>
</style>